export default {
  // could use only this getter and use it for both token and logged
  session: state => state.session,
  // or could have both getters separated
  logged: state => state.session.logged,
  // state to get the included
  activeDashboard: state => state.activeDashboard,
  token: state => state.session.token,
  status: state => state.session.user.status,
  roles: state => state.session.roles,
  permissions: state => state.session.permissions,
  longNotification: state => state.notifications.long,
  shortNotification: state => state.notifications.short,
  tagColors: state => state.tagColors,
  coins: state => state.data.coins,
  universal: state => state.data.universal,
  coinsLoader: state => state.loadingStatus.coins,
  coinDatapoints: state => state.coinDatapointsConfig,
  loggedIn: state => state.session.logged,
  sidebarCollapsed: state => state.sidebarCollapsed,
  sidebarCollapsedInt: state => state.sidebarCollapsedInt,
  hasSidebar: state => state.hasSidebar,
  coinSidebar: state => state.sidebars.coin,
  correlatedSidebar: state => state.sidebars.correlated,
  addToWatchlistModal: state => (state.modal.name == 'addToWatchlist' ? true : false),
  watchlistModal: state => (state.modal.name == 'watchlistModal' ? true : false),
  metricComparisonModal: state => (state.modal.name == 'comparisonModal' ? true : false),
  analysisSettingsSidebar: state => (state.modal.name == 'analysisSettings' ? true : false),
  metricTooltips: state => state.metricTooltips,
  widgetChannelOutput: state => channel => {
    return state.widgetOutputChannels[channel];
  },
  user_roles: state => state.user_roles,
  team_roles: state => state.team_roles,
  client: state => state.streamClient,
  streamToken: state => state.streamUserToken,
  streamUser: state => state.streamUser,
  giphyKey: state => state.giphyApiKey,
  unreadChannelsCount: state => state.unreadChannelsCount,
  streamCompanies: state => state.streamCompanies,
  channels: state => state.channels,
  rejectedChannels: state => state.rejectedChannels,
  mutedChannels: state => state.mutedChannels,
  requestChannels: state => state.requestChannels,
  folders: state => state.folders,
  streamUsers: state => state.streamUsers,
  apiRoles: state => state.apiRoles,
  staticDataLoader: state => state.loadingStatus.static,
  coinChain: state => state.coinChain,
  defaultFeeds: state => state.defaultFeeds,
  channelsLoaded: state => state.channelsLoaded,
  onchainAddresses: state => state.onchainAddresses,
  onchainAddressesModal: state => state.onchainAddressesModal,
  onchainFolderModal: state => state.onchainFolderModal,
  unlockCoins: state => state.unlockCoins,
  allApiKeys: state => state.apiKeys.allApiKeys,
  apiKeysDropdownOptions: state => state.apiKeys.apiKeyOptions,
  providers: state => state.datasetProviders,
  gasFees: state => state.gasFees,
  gasFeesLoader: state => state.gasFeesLoader,
  categories: state => state.categories
};
