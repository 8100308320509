import notify from '@/composeables/notify.js';
import store from '@/store/index';
import { router } from '@inertiajs/vue3';
import { DateTime } from 'luxon';
import { downloadToCSV } from '@/util/csvDownload';

export function getColor() {
  let color = '#';
  for (let i = 0; i < 3; i++)
    color += ('0' + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
  return color;
}

export function fixScrolling(action) {
  let hoverElem = document.querySelector('.element-hover');
  if (hoverElem !== null) {
    if (action === 'down') {
      hoverElem.scrollIntoView({ block: 'nearest' });
    } else if (action === 'up') {
      if (hoverElem.previousElementSibling) {
        hoverElem.previousElementSibling.scrollIntoView({ block: 'nearest' });
      } else {
        hoverElem.scrollIntoView({ block: 'nearest' });
      }
    }
  }
}
export function copyToClipboard(textToCopy, message = 'Copied to clipboard!') {
  window.navigator.clipboard.writeText(textToCopy).then(() => {
    //debug why flash doesn't work here for some reason
    flashMessage({
      type: 'success',
      message: message
    });
  });
}

export function goToPreviousPage() {
  //2 just to make sure we are not landing directly on the page
  if (window.history.length && window.history.length > 2) {
    window.history.back();
  } else {
    router.visit('/dashboard');
  }
}

export function flashMessage(content, clickEvent = null) {
  notify(
    {
      group: 'app',
      type: 'flash',
      text: content,
      onClick: clickEvent
    },
    5000
  );
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function tooltipDefinitionExists(id) {
  let metricTooltips = store.getters.metricTooltips;
  if (metricTooltips) {
    return metricTooltips.findIndex(x => x.metric_key == id) >= 0;
  }
  return false;
}

export function applyRawCSS(cssString) {
  if (cssString && cssString.trim() != '') {
    const matches = cssString.matchAll(/\.(\w.+){\n?([^}]*)\n?}/g);
    for (const match of matches) {
      document.querySelectorAll('.' + match[1]).forEach(el => {
        el.style = match[2].replace(/[\r\n\t]/g, '').replaceAll(';', ' !important;');
      });
    }
  }
}

export function applyFontFamily(fontString, fontFace) {
  try {
    document.querySelectorAll(`[id='custom-font-style']`).forEach(element => {
      document.head.removeChild(element);
    });
    if (fontFace) {
      let fonts = fontString
        .split('@font-face')
        .filter(x => x != '')
        .map(x => '@font-face' + x);
      const styleEl = document.createElement('style');
      styleEl.id = 'custom-font-style';
      document.head.appendChild(styleEl);
      var sheet = styleEl.sheet;
      fonts.forEach(font => {
        sheet.insertRule(font, sheet.cssRules.length);
      });
    } else {
      let links = fontString.split(',').map(el => el.trim());
      links.forEach(str => {
        if (str.trim().length != 0) {
          var link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = str;
          link.id = 'custom-font-style';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
      });
    }
  } catch (error) {
    // do nothing
  }
}

export function sortByMarketCapAllCategories(column) {
  // arrange categories and subcategories on MCap base value
  let categoriesList = [];
  let subCategoriesList = [];
  let allCategories = store.state.categories.coin || [];
  let allSubCategories = store.state.categories.coin_sub_categories;
  let coins = store.state.data.coins;
  if (column.includeCategories) {
    let marketCapList = [];
    categoriesList =
      allCategories?.map(x => {
        if (x.flat_coin_uids) {
          marketCapList = coins.map(c => {
            if (x.flat_coin_uids.includes(c.coin_uid)) {
              return parseFloat(c.market_cap);
            }
            return 0;
          });
        }
        return {
          id: 'cat_' + x.id,
          label: x.name,
          flat_coin_uids: x.flat_coin_uids,
          mCap: marketCapList.filter(Boolean).reduce((partialSum, a) => parseFloat(partialSum) + parseFloat(a), 0)
        };
      }) || [];
  }

  if (column.includeSubcategories) {
    let marketCapList = [];
    subCategoriesList =
      allSubCategories?.map(x => {
        if (x.flat_coin_uids) {
          marketCapList = coins.map(c => {
            if (x.flat_coin_uids.includes(c.coin_uid)) {
              return parseFloat(c.market_cap);
            }
            return 0;
          });
        }
        return {
          id: 'subCat_' + x.id,
          label: x.name,
          cat_id: x.category_id,
          flat_coin_uids: x.flat_coin_uids,
          mCap: marketCapList.filter(Boolean).reduce((partialSum, a) => parseFloat(partialSum) + parseFloat(a), 0)
        };
      }) || [];
  }

  return [...categoriesList, ...subCategoriesList].sort((a, b) => (a.mCap > b.mCap ? -1 : 1));
}

export function getScrollPositionFromBottom(container) {
  var scrollTop = container.scrollTop;
  var clientHeight = container.clientHeight;
  var scrollHeight = container.scrollHeight;

  return scrollHeight - scrollTop - clientHeight;
}

export function getDateRanges(duration) {
  const today = new Date();
  const dayOfMonth = today.getDate();
  let ranges = [];
  let startDate, endDate;
  if (duration.endsWith('d')) {
    const days = parseInt(duration.slice(0, -1), 10);
    startDate = new Date(today);
    startDate.setDate(today.getDate() - days);
    endDate = today;
    ranges = [{ startDate: startDate, endDate: endDate }];
  } else if (duration.endsWith('m')) {
    const months = parseInt(duration.slice(0, -1), 10);

    for (let i = 0; i < months; i++) {
      startDate = new Date(today.getFullYear(), today.getMonth() - i - 1, dayOfMonth);
      endDate = new Date(today.getFullYear(), today.getMonth() - i, dayOfMonth - 1);
      ranges.unshift({ startDate, endDate });
    }

    const lastRange = ranges[ranges.length - 1];
    lastRange.endDate = today;
  }
  return ranges;
}

export function getNumberPrecision(num) {
  if (typeof num === 'undefined' || num === null || num === '') {
    return 2;
  }
  num = parseFloat(num);
  return num < 1 ? (num < 0.001 ? getSmallNumberPrecision(num) : 6) : 2;
}

export function getSmallNumberPrecision(value) {
  var precision = 2,
    i = 1;
  let n = value,
    limit = Math.pow(10, 12);
  while (n < 10 && i <= limit) {
    n = n * 10;
    i *= 10;
  }
  if (i <= limit) {
    precision = Math.round(Math.log(i) / Math.log(10));
  }
  return precision;
}

export function getMinMove(precision) {
  return 1 / Math.pow(10, precision);
}

export function getCurrentLocation() {
  // supported timezones by tv-charts
  const CustomTimezones = [
    'Africa/Cairo',
    'Africa/Johannesburg',
    'Africa/Lagos',
    'America/Argentina/Buenos_Aires',
    'America/Bogota',
    'America/Caracas',
    'America/Chicago',
    'America/El_Salvador',
    'America/Juneau',
    'America/Lima',
    'America/Los_Angeles',
    'America/Mexico_City',
    'America/New_York',
    'America/Phoenix',
    'America/Santiago',
    'America/Sao_Paulo',
    'America/Toronto',
    'America/Vancouver',
    'Asia/Almaty',
    'Asia/Ashkhabad',
    'Asia/Bahrain',
    'Asia/Bangkok',
    'Asia/Chongqing',
    'Asia/Dubai',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Jakarta',
    'Asia/Jerusalem',
    'Asia/Kathmandu',
    'Asia/Kolkata',
    'Asia/Kuwait',
    'Asia/Muscat',
    'Asia/Qatar',
    'Asia/Riyadh',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Taipei',
    'Asia/Tehran',
    'Asia/Tokyo',
    'Atlantic/Reykjavik',
    'Australia/ACT',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Perth',
    'Australia/Sydney',
    'Europe/Amsterdam',
    'Europe/Athens',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Brussels',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Europe/Lisbon',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Moscow',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Vilnius',
    'Europe/Warsaw',
    'Europe/Zurich',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'Pacific/Fakaofo',
    'Pacific/Honolulu',
    'Pacific/Norfolk',
    'US/Mountain'
  ];
  const currentLocation = DateTime.local();
  if (CustomTimezones.includes(location)) {
    return currentLocation.zoneName;
  }
  // match utc offsets and return a supported location with same offset as current location
  else {
    for (const [i] of CustomTimezones.entries()) {
      if (DateTime.local().setZone(CustomTimezones[i]).offset === currentLocation.offset) {
        return CustomTimezones[i];
      }
    }
  }
}

export function getStoredLocation() {
  return window.localStorage.getItem('tr_tie_location');
}

export function getChainLink(chainId, hash, extensionKey) {
  const chain = store.state.supportedChains.find(x => x.id == chainId);
  if (chain) {
    const extension = chain.addressExtension[extensionKey];
    return `${chain.address}/${extension ? extension + '/' : ''}${hash}`;
  }
  return '';
}

export function generateProviderName(provider_id) {
  const provider = store.getters.providers.find(x => x.id == provider_id);
  let name = provider.name.toLowerCase().replaceAll(' ', '_');
  let currentNames = store.getters.allApiKeys
    .filter(x => x.name.startsWith(name) && x.name != 'the_tie_api_v1')
    .map(x => x.name)
    .sort((a, b) => {
      const aArr = a.split('_');
      const bArr = b.split('_');
      return (parseInt(aArr[aArr.length - 1]) || 0) > (parseInt(bArr[bArr.length - 1]) || 0) ? 1 : -1;
    });
  if (currentNames.length) {
    const lastUsedName = currentNames[currentNames.length - 1];
    const lastUsedNameArr = lastUsedName.split('_');
    return name + '_' + (parseInt(lastUsedNameArr[lastUsedNameArr.length - 1]) + 1);
  } else {
    return name + '_1';
  }
}

export function checkUserApiKeysErrors(source, is_update = false) {
  if (!source.dataset_provider_id) return 'Please select a valid provider';
  const name = source.name.trim();
  if (name == '') return 'Variable name must exist';
  if (name.includes(' ')) return 'Variable name should not contain spaces';
  const allApiKeys = store.getters.allApiKeys;
  const currentNames = (is_update ? allApiKeys.filter(x => x.id != source.id) : allApiKeys).map(x => x.name);
  if (currentNames.includes(name)) return 'Same variable name already exists';
  if (source.token.trim() == '') return 'Value must exist';
  return true;
}

export function arraysAreSame(a, b, key = null) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((x, idx) => {
      return key ? x[key] == b[idx][key] : x == b[idx];
    })
  );
}

/*
 * This function combines all the given array in such
 * a manner that the final result has atleast one item
 * of every array in it i.e.
 * getAllCombinations([['a', 'b'], ['c'], ['d', 'e']])
 * result => [["a","c","d"],["a","c","e"],["b","c","d"],["b","c","e"]]
 */
export function getAllCombinations(arraysToCombine) {
  const divisors = [];
  let combinationsCount = 1;
  for (let i = arraysToCombine.length - 1; i >= 0; i--) {
    divisors[i] = divisors[i + 1] ? divisors[i + 1] * arraysToCombine[i + 1].length : 1;
    combinationsCount *= arraysToCombine[i].length || 1;
  }

  const getCombination = (n, arrays, divisors) =>
    arrays.reduce((acc, arr, i) => {
      acc.push(arr[Math.floor(n / divisors[i]) % arr.length]);
      return acc;
    }, []);

  const combinations = [];
  for (let i = 0; i < combinationsCount; i++) {
    combinations.push(getCombination(i, arraysToCombine, divisors));
  }
  return combinations;
}

export function getCoinVersion(id) {
  return ['V1', 'V2', 'V2.1', 'V3'].some(v => id.toLowerCase().endsWith(v.toLowerCase()))
    ? id.split(' ').pop().toUpperCase()
    : 'V1';
}

export function getChainName(chainId) {
  let chains = { All: 'All Chains', onchain_avalanche: 'Avalanche' };
  store.state.supportedChains.forEach(c => (chains[c.id] = c.text));
  return chains[chainId] || chainId;
}

export function downloadChartAsCSV(keys, series, filename, datetime = false) {
  let csvData = {};
  series.forEach(s => {
    s.data.forEach(d => {
      const key = datetime ? DateTime.fromMillis(d[0]).toFormat('LLL-dd-yyyy') : d[0];
      if (!csvData[key]) {
        csvData[key] = {
          Datetime: key,
          [`${s.name}`]: d[1] ? d[1] : ''
        };
      } else {
        csvData[key] = {
          ...csvData[key],
          ...{ [`${s.name}`]: d[1] ? d[1] : '' }
        };
      }
    });
  });
  downloadToCSV(keys, Object.values(csvData), filename);
}

export default {
  getColor,
  fixScrolling,
  groupBy,
  applyRawCSS,
  applyFontFamily,
  sortByMarketCapAllCategories,
  getScrollPositionFromBottom,
  getDateRanges,
  getNumberPrecision,
  getSmallNumberPrecision,
  getMinMove,
  getCurrentLocation,
  getStoredLocation,
  getChainLink,
  generateProviderName,
  checkUserApiKeysErrors,
  arraysAreSame,
  getAllCombinations,
  getCoinVersion,
  getChainName
};
