import axios from 'axios';
import uniqBy from 'lodash/uniqBy';

export async function updateAddress(params) {
  try {
    let response = await axios.patch(`/onchain_addresses/${params.id}`, { ...params });
    return response.data;
  } catch {
    return [];
  }
}

export async function deleteAddress(id) {
  await axios.delete(`/onchain_addresses/${id}`);
}

export async function createAddresses(params) {
  try {
    let response = await axios.post('/onchain_addresses', { ...params });
    return response.data;
  } catch {
    return [];
  }
}

export async function getAddresses(params) {
  try {
    let response = await axios.get('/onchain_addresses', params ? { params: { ...params } } : {});
    return response.data;
  } catch {
    return [];
  }
}
export function getAddressTooltipContent(address, allAddresses, folders) {
  let addressObj = allAddresses?.find(i => i.address === address);
  folders = uniqBy(
    folders.filter(i => addressObj?.watchlist_ids?.includes(i.id)),
    'id'
  )
    ?.map(i => i.name)
    ?.join(', ');
  address = addressObj?.address || address;
  let html = ['<div class="space-y-2 p-2">'];
  html.push(
    `<div class"text-white text-xs"><span class="block font-bold">Address:</span> <span class="block text-gray-200"> ${address}
    </span></div>`
  );
  if (folders.length) {
    html.push(
      `<div class"text-white text-xs"><span class="block font-bold">Included in Folders:</span> <span class="block text-gray-200">
       ${folders}
       </span></div>`
    );
  }
  html.push('</div>');
  return html.join('');
}
