import { DateTime } from 'luxon';
import store from '@/store/index';
import { getNumberPrecision } from '@/composeables/helpers';

export function percentage(val) {
  if (typeof val === 'undefined' || val === null || val === '' || val === 'N/A') {
    return '';
  }
  return parseFloat(val) > 0 ? `+${val}%` : `${val}%`;
}
export function formatNumber(num, digits = 2, prefix = '') {
  if (typeof num === 'undefined' || num === null || num === '') {
    return '';
  }
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
    { value: 1e21, symbol: 'Z' },
    { value: 1e24, symbol: 'Y' },
    { value: 1e27, symbol: 'R' },
    { value: 1e30, symbol: 'Q' }
  ];
  var negative = [
    { value: -1, symbol: '' },
    { value: -1e3, symbol: 'k' },
    { value: -1e6, symbol: 'M' },
    { value: -1e9, symbol: 'B' },
    { value: -1e12, symbol: 'T' },
    { value: -1e15, symbol: 'P' },
    { value: -1e18, symbol: 'E' },
    { value: -1e21, symbol: 'Z' },
    { value: -1e24, symbol: 'Y' },
    { value: -1e27, symbol: 'R' },
    { value: -1e30, symbol: 'Q' }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  if (num >= 0) {
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
  } else {
    for (i = si.length - 1; i > 0; i--) {
      if (num <= negative[i].value) {
        break;
      }
    }
  }
  let value = (num / si[i].value).toFixed(digits).replace(rx, '$1');
  if (value < 0) {
    value = '-' + prefix + Math.abs(value);
  } else {
    value = prefix + value;
  }

  return value + si[i].symbol;
}

export function decimals(value, minDecimals = 4, maxDecimals = 4) {
  if (typeof value === 'undefined' || value === null || value === '' || isNaN(value)) {
    return '';
  }
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  }).format(value);
}

export function getRandomId() {
  return Math.random().toString(36).substr(0, 8);
}

export function truncate(value, maxChars) {
  if (value && value.length > maxChars) {
    return value.slice(0, maxChars) + '...';
  }
  return value;
}

export function toChatTime(timestamp, format = '') {
  let datetime = '';
  if (typeof timestamp === 'string') {
    datetime = DateTime.fromISO(timestamp);
  } else {
    datetime = DateTime.fromJSDate(timestamp);
  }
  if (format == 'channel') {
    if (datetime > DateTime.now().startOf('day')) {
      return datetime.toLocaleString(DateTime.TIME_SIMPLE) + ' today';
    } else {
      return datetime.toFormat('h:mm a') + ' on ' + datetime.toFormat('MMMM dd, yyyy');
    }
  } else if (format == 'chat_start') {
    if (datetime > DateTime.now().startOf('day')) {
      return 'Today';
    } else {
      return datetime.toFormat('MMMM dd, yyyy');
    }
  } else if (datetime > DateTime.now().startOf('day')) {
    if (format === 'simple') {
      return datetime.toLocaleString(DateTime.TIME_SIMPLE);
    } else {
      return 'Today at ' + datetime.toLocaleString(DateTime.TIME_SIMPLE);
    }
  } else {
    return datetime.toFormat('MM/dd/yyyy');
  }
}

export function roundSmallPercent(value, minDecimals = 2, maxDecimals = 4) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return '';
  }
  if (value < 0.01) {
    minDecimals = 4;
    maxDecimals = 4;
  }
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  }).format(value);
}

export function rounded(value, decimalPlaces = 2) {
  if (typeof value === 'undefined' || value === null || value === '' || isNaN(value)) {
    return '';
  }
  var decimalNumber = typeof value === 'string' ? parseFloat(value) : value;

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(decimalNumber);
}

export function roundedPercent(value, decimalPlaces = 2) {
  const r = rounded(value, decimalPlaces);
  return r ? r + '%' : '';
}

export function usdCryptoPrice(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return '';
  }

  var decimals = getNumberPrecision(value);
  var prefix = value < 0 ? '-' : '';
  value = value < 0 ? value * -1 : value;
  var price = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value);
  if (price != 0) return prefix + '$' + price;

  price = formatSmallNumbers(value);
  return prefix + '$' + price;
}

export function btcCryptoPrice(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return '';
  }
  var inSats = false;
  if (value <= 0.0001) {
    inSats = true;
    value = value * 100000000;
  }
  var rounded = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 6,
    maximumFractionDigits: 6
  }).format(value);

  return inSats ? `${rounded} sats` : `${rounded} BTC`;
}

export function readableDate(date, utc = false) {
  if (!date) {
    return '';
  }
  if (utc) {
    return DateTime.fromISO(date).setZone('UTC').toFormat('LLL dd, yyyy');
  } else {
    return DateTime.fromISO(date).toFormat('LLL dd, yyyy');
  }
}

export function readableDateTime(date) {
  if (!date) {
    return '';
  }
  return DateTime.fromJSDate(date).toFormat('LLL dd, yyyy h:mma');
}

export function minimalTimeWithinToday(date) {
  if (!date) {
    return '';
  }
  // if today then remove YYYY-MM-DD
  let datetime = null;
  if (isNaN(date)) {
    datetime = DateTime.fromISO(date);
  } else {
    datetime = DateTime.fromMillis(date);
  }
  if (datetime.toFormat('yyyy-MM-dd') === DateTime.now().toFormat('yyyy-MM-dd')) {
    return datetime.toFormat('h:mma');
  }

  // if same year, remove the year
  if (datetime.toFormat('yyyy') === DateTime.now().toFormat('yyyy')) {
    return datetime.toFormat('LLL dd, h:mm a');
  }

  return datetime.toFormat('LLL dd, yyyy h:mm a');
}

export function relativeTime(date) {
  return DateTime.fromISO(date).setZone('UTC').toRelative();
}

export function formatPrice(num) {
  const precision = getNumberPrecision(num);
  const dec = Math.pow(10, precision);
  var price = (Math.round(Number(num) * dec) / dec).toLocaleString(undefined, {
    minimumFractionDigits: precision
  });
  if (price != 0) return price;

  return formatSmallNumbers(num);
}

export function slugify(str, separator = '-') {
  return str
    .trim()
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, separator);
}

export function roundedBtc(value, decimalPlaces = 2) {
  if (typeof value === 'undefined' || value === null || value === '' || isNaN(value)) {
    return '';
  }
  var decimalNumber = typeof value === 'string' ? parseFloat(value) : value;
  if (value < 1) {
    decimalPlaces = getNumberPrecision(value);
  }
  let price_btc = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(decimalNumber);
  if (price_btc != 0) return price_btc;

  return formatSmallNumbers(value);
}

export function titleize(str, separator = '-') {
  return str
    ?.split(separator)
    ?.map(word => {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    })
    ?.join(' ');
}

export function upperCase(str, separator = '-') {
  return str
    .split(separator)
    .map(word => {
      return word.toUpperCase();
    })
    .join(' ');
}

export function formatPricing(n) {
  if (typeof n === 'undefined' || n === null) return '';
  return n.toLocaleString();
}

export function formatBigPricing(n) {
  if (typeof n === 'undefined' || n === null) return '';
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
}

export function formatPercent(n) {
  if (typeof n === 'undefined' || n === null) return '';
  n = parseFloat(n);
  return (
    (n < 0
      ? n.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : '+' + n.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) + '%'
  );
}

export function humanReadableToNumber(n) {
  if (typeof n === 'undefined' || n === null) return '';
  n = n.replace('$', '').toLowerCase();
  if (n.includes('k')) return parseFloat(n) * 1e3;
  if (n.includes('m')) return parseFloat(n) * 1e6;
  if (n.includes('b')) return parseFloat(n) * 1e9;
  if (n.includes('t')) return parseFloat(n) * 1e12;
  if (n.includes('p')) return parseFloat(n) * 1e15;
  if (n.includes('e')) return parseFloat(n) * 1e18;
  else return n;
}

export function getSentimentClassAndText(sentiment) {
  let sentimentClasses = store.state.sentimentClasses;
  let text = '';
  if (sentiment >= 80) {
    text = 'Very Bullish';
  } else if (sentiment >= 60) {
    text = 'Bullish';
  } else if (sentiment >= 40) {
    text = 'Neutral';
  } else if (sentiment >= 20) {
    text = 'Bearish';
  } else {
    text = 'Bearish';
  }
  return sentimentClasses[text];
}

export function getPageCursor(link) {
  let url = new URL(link);
  return url.searchParams.get('marker');
}

export function getCurrencySymbol(cur) {
  if (!cur) return '$';
  return {
    USD: '$',
    GBP: '£',
    Chf: 'CHf'
  }[cur];
}

export function getFilterOperator(key) {
  let operators = {
    Equals: 'eq',
    'Less Than': 'lt',
    'Greater Than': 'gt',
    'Less Than or equals': 'lte',
    'Greater Than or equals': 'gte',
    'Not Equals': 'neq',
    Contains: 'like',
    'Does Not Contain': 'nlike',
    'Is Exactly': 'eq',
    'After Date': 'gt',
    'Before Date': 'lt',
    'Exact Date': 'eq',
    'In Range': ['gte,lte']
  };
  return operators[key];
}

export function formatSmallNumbers(num) {
  var i = 1;
  let n = num,
    limit = Math.pow(10, 12);
  while (n < 10 && i <= limit) {
    n = n * 10;
    i *= 10;
  }
  if (i > limit) return 0;
  return (parseInt(n) / i).toFixed(Math.round(Math.log(i) / Math.log(10)));
}

export function formatCompanyRound(round, separator = ' ') {
  const roundMapping = {
    'M AND A': 'M&A',
    'BUYOUT LBO': 'Buyout/LBO'
  };
  const symbolMapping = {
    PP: 'PP',
    VC: 'VC',
    IPO: 'IPO',
    '(Lead)': '(Lead)'
  };
  if (roundMapping[round]) return roundMapping[round];
  return round
    ?.split(separator)
    ?.map(word => {
      if (symbolMapping[word]) return symbolMapping[word];
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    })
    ?.join(' ');
}

export default {
  getRandomId,
  percentage,
  formatNumber,
  truncate,
  toChatTime,
  decimals,
  roundSmallPercent,
  rounded,
  roundedPercent,
  usdCryptoPrice,
  btcCryptoPrice,
  readableDate,
  readableDateTime,
  minimalTimeWithinToday,
  relativeTime,
  formatPrice,
  slugify,
  roundedBtc,
  titleize,
  upperCase,
  formatPricing,
  formatPercent,
  formatBigPricing,
  humanReadableToNumber,
  getSentimentClassAndText,
  getPageCursor,
  getCurrencySymbol,
  getFilterOperator,
  formatSmallNumbers
};
