import useHttp from '@/composeables/http';
import { useStore } from 'vuex';
import { DateTime } from 'luxon';
import { formatNumber } from '@/composeables/filters';
import { ref } from 'vue';

export function useCoinUnlockApi() {
  const $http = useHttp();
  const store = useStore();

  async function getVestingSeries(coin_uid, interval = '1 month') {
    const vestingResponse = await $http.get('/unlock/vesting', {
      params: {
        coin_uid,
        interval
      }
    });

    const series = new Map();

    vestingResponse.data.forEach(month => {
      const date = DateTime.fromISO(month.datetime).ts;

      month.amount_unlocked_by_holder.forEach(holder => {
        const seriesName = `${holder.type}: ${holder.entity}`;
        const seriesData = [date, holder.amount];
        if (series.get(seriesName)) {
          series.set(seriesName, [...series.get(seriesName), seriesData]);
        } else {
          series.set(seriesName, [seriesData]);
        }
      });
    });

    const vesting = [];
    series.forEach((v, k) => {
      vesting.push({
        name: `${k} - ${formatNumber(v[v.length - 1][1])}`,
        data: v,
        connectNulls: false,
        step: 'right'
      });
    });

    return vesting.sort((a, b) => {
      const aLen = a.data.length;
      const bLen = b.data.length;
      if (bLen != aLen) return aLen - bLen;

      const aLast = a.data[aLen - 1][1];
      const bLast = b.data[bLen - 1][1];
      if (aLast != bLast) return bLast - aLast;

      return b.name - a.name;
    });
  }

  async function getAllocationSeries(coin_uid) {
    const vestingSeries = await getVestingSeries(coin_uid);

    const coin = store.getters.coins.find(c => c.uid == coin_uid);
    const totalSupply = coin.total_supply ? parseFloat(coin.total_supply) : 0;

    let scheduleTotal = 0;
    const allocationSeries = vestingSeries.map(series => {
      const name = series.name.split(' - ')[0];
      const data = series.data[series.data.length - 1][1];
      scheduleTotal += data;
      return {
        name,
        y: data
      };
    });

    return {
      name: 'Allocations',
      data: allocationSeries.map(series => {
        const percent = parseFloat(((series.y / (totalSupply || scheduleTotal)) * 100).toFixed(2));
        return {
          name: `${series.name} - ${percent}%`,
          y: series.y
        };
      }),
      dataLabels: false
    };
  }

  async function getSupplyData(coin_uid) {
    const supplyResponse = await $http.get('/unlock/supply', {
      params: {
        coin_uid
      }
    });

    return supplyResponse.data;
  }

  async function getInvestorData(coin_uid) {
    const investorResponse = await $http.get('/unlock/investors', {
      params: {
        coin_uid
      }
    });

    return investorResponse.data;
  }

  async function getGeneralComments(coin_uid) {
    const commentsResponse = await $http.get('/unlock/comments', {
      params: {
        coin_uid
      }
    });

    return commentsResponse.data.length == 1 ? commentsResponse.data[0].general_comments : null;
  }

  async function getHolders() {
    const holders = await $http.get('/unlock/holders');

    return holders.data;
  }

  async function getCoins() {
    const coins = await $http.get('/unlock/coins');

    return coins.data;
  }

  async function getEventsData(params) {
    const eventsResponse = await $http.get('/unlock/events', {
      params
    });

    const coinsResponse = await $http.get('/unlock/coins');
    const coinMap = new Map();

    coinsResponse.data.forEach(coin => {
      coinMap.set(coin.coin_uid, {
        ticker: coin.ticker,
        name: coin.name
      });
    });

    const eventData = eventsResponse.data.data;
    let marker = null;

    if ('links' in eventsResponse.data) {
      const eventNextLink = eventsResponse.data?.links['next'];
      const nextLinkParams = eventNextLink.split('&');
      const markerParam = nextLinkParams.find(param => param.startsWith('marker='));
      if (markerParam) marker = markerParam.split('=')[1];
    }

    const availableCoins = Array.from(coinMap.keys());
    return {
      data: eventData
        .filter(e => availableCoins.includes(e.coin_uid))
        .map(event => {
          const coin = coinMap.get(event.coin_uid);
          return {
            ...event,
            coin: {
              uid: event.coin_uid,
              ticker: coin?.ticker || '',
              name: coin?.name || '',
              href: `/coin/${event.coin_uid}/unlocks`
            },
            amount: event.unlock_value,
            amount_usd: event.unlock_value_usd,
            amount_usd_historical: event.unlock_value_usd_historical
          };
        }),
      marker
    };
  }

  async function getNextEvent(coin_uid) {
    const todayDate = DateTime.now().toFormat('yyyy-MM-dd');

    const eventsResponse = await $http.get('/unlock/events', {
      params: {
        coin_uids: coin_uid,
        avg_trading_volume_percent: 'gte:0',
        market_cap_percent: 'gte:0',
        supply_percent: 'gte:0',
        unlock_entities: 'any',
        unlock_types: 'any',
        unlock_date: `gte:${todayDate}`,
        sort: 'unlock_date:asc'
      }
    });

    const events = eventsResponse.data.data;

    if (events && events.length > 0) {
      const event = events[0];
      return {
        ...event,
        amount: event.unlock_value,
        amount_usd: event.unlock_value_usd
      };
    }

    return null;
  }

  async function getAllocationData(coin_uid) {
    const allocationResponse = await $http.get('/unlock/allocations', {
      params: {
        coin_uid
      }
    });

    return allocationResponse.data;
  }

  return {
    getVestingSeries,
    getAllocationSeries,
    getSupplyData,
    getInvestorData,
    getGeneralComments,
    getHolders,
    getCoins,
    getEventsData,
    getNextEvent,
    getAllocationData
  };
}
