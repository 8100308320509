<template>
  <BaseDialog
    :model-value="options.show"
    size-class="!max-w-sm"
    style="z-index: 1000 !important"
    @update:model-value="handleReject()"
  >
    <template #header>
      <div class="flex items-center text-sm font-medium">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2 h-6 w-6 text-red-900"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
        <span>{{ options.title }}</span>
      </div>
    </template>
    <template #default>
      <div class="flex items-center text-sm" v-html="options.message"></div>
    </template>
    <template #footer>
      <div class="ml-auto mt-5 flex space-x-2">
        <BaseButton type="secondary" size="sm" @press="handleReject()">{{ options.rejectText }}</BaseButton>
        <BaseButton type="danger" size="sm" @press="handleConfirm()">{{ options.confirmText }}</BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const $store = useStore();
const options = computed(() => {
  return $store.state.confirmationDialogOptions;
});
function handleConfirm() {
  options.value.show = false;
  setTimeout(() => {
    $store.commit('resolveConfirmation', true);
  }, 100);
}
function handleReject() {
  options.value.show = false;
  setTimeout(() => {
    $store.commit('resolveConfirmation', false);
  }, 100);
}
</script>
