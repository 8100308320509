import ObjectToCSV from 'object-to-csv';

export function downloadToCSV(keys, data, filename) {
  let otc = new ObjectToCSV({
    keys: keys,
    data: data
  });
  const url = window.URL.createObjectURL(new Blob([otc.getCSV()]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.csv`);
  document.body.appendChild(link);
  link.click();
}
